h1,
h2,
h3,
h4 {
  margin: 0;
}

html,
body {
  padding: 0;
  margin: 0;
}

body > #root > div {
  height: 100vh;
}

body {
  font-family: "Space Grotesk";
}
